html, body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}